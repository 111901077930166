@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700&family=Roboto:ital,wght@0,300;0,400;0,700;0,900;1,700;1,900&display=swap');

*{
    font-family: "Manrope";
}

/* .css-1a44bfa-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 15px !important;
} */

/* put overriding CSS here,  
because of the configuration of the code it may be 
neccessary to include !important */

/* for feedback thumbs up thumbs down */
/* .feedback-icons-positive{
  color: #E8EAF6 !important;
  padding: .125em;
}

.feedback-icons-positive:hover{
  color:green !important;
}

.feedback-icons-negative{
  color: #E8EAF6 !important;
  padding: .125em;
}

.feedback-icons-negative:hover{
  color: red !important;
} */

.message-text[data-v-4f01cfd4], div.message-text {font-size:15px;}